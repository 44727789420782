import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import "./mobile-products-page.css";

const ProductCard = ({ title, description, imageSrc, learnMoreUrl }) => (
  <div className="product-card">
    <div className="product-info">
      <h2>{title}</h2>
      <p>{description}</p>
      <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
        <button className="mob-products-learn-more-btn">
          Learn More <FaLongArrowAltRight />
        </button>
      </a>
    </div>
    {imageSrc && (
      <div className="product-image">
        <img src={imageSrc} alt={title} />
      </div>
    )}
  </div>
);

const MobileProductsPage = () => {
  return (
    <div className="mobile-products-page">
      <h1 className="mobile-products-title">PRODUCTS</h1>
      <p className="mobile-products-subtitle">
      Our innovative educational solutions include an interactive quiz app and an AI-powered online examination platform, designed to enhance learning and streamline assessments. The quiz app offers engaging, subject-based tests with real-time feedback, while the AI-driven examination platform ensures secure, automated proctoring and personalized assessments for a seamless testing experience. Both tools aim to boost knowledge retention and provide valuable insights for educators and learners alike.
      </p>
      <div className="product-cards">
        <ProductCard
          title="EXAMHUB"
          description="ExamHUB is an award-winning education app offering gamified
                quizzes, personalized learning, and multi-syllabus support for
                Global students."
          imageSrc="/images/examhub-mob.png"
          learnMoreUrl="https://examhub.lk/"
        />
        <ProductCard
          title="FEDES AI"
          description="FEDES AI is an innovative education solution that uses real-time
                emotion detection technology to enhance student engagement and
                learning outcomes during both online and in-person classes."
          imageSrc="/images/fedes-mob.png"
          learnMoreUrl="https://fedesai.com/"
        />
      </div>
    </div>
  );
};

export default MobileProductsPage;
