import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
  FaWhatsapp,
  FaInstagram,
  FaRegPaperPlane,
  FaBuilding,
  FaPhoneAlt,
  FaRegEnvelope,
} from "react-icons/fa";
import "./contact-page.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    whatsappNo: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    emailjs.init("gc1AZiZX1-g6gtC5k");
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      from_email: formData.email,
      whatsapp_no: formData.whatsappNo,
      message: formData.message,
    };

    try {
      await emailjs.send("service_4q8bzak", "template_1d7f578", templateParams);

      setSubmitStatus("success");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        whatsappNo: "",
        message: "",
      });
    } catch (error) {
      console.error("Failed to send email:", error);
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="contact-page-container">
        <div className="contact-page-header">
          <div className="page-contact-title">
            <h1>CONTACT</h1>
          </div>
        </div>
        <div className="contact-section">
          <div className="contact-title">
            <h1>
              LET'S WORK <span>TOGETHER.</span>
            </h1>
            <h5>
              Reach out, and let's create a universe of possibilities together!
            </h5>
          </div>
          <div className="main-contact-content" style={{ display: "flex" }}>
            <div className="contact-form-container-1">
              <div className="separate-bg-contact">
                <h1>FONIX SOFTWARE SOLUTIONS PVT LTD</h1>
                <p>
                  Get in touch with FONIX Software Solutions Pvt Ltd, your
                  trusted partner for innovative tech solutions. Whether you
                  need web development, mobile apps, AI-based products, or
                  digital marketing services, our team is here to help. Reach
                  out today to explore how we can turn your ideas into reality
                  and drive your business forward.
                </p>

                <div className="contact-info">
                  <p
                    className="contact-info-title"
                    style={{ fontWeight: "bold" }}
                  >
                    CONTACT US
                  </p>
                  <p>
                    <FaBuilding />
                    &nbsp; 263/A, Eththota, Gallalla, Ratnapura, LK
                  </p>
                  <p>
                    <FaPhoneAlt />
                    &nbsp; +94 77 050 8710
                  </p>
                  <p>
                    <FaRegEnvelope />
                    &nbsp; contact@fonixss.com
                  </p>
                </div>
              </div>
            </div>
            <div className="contact-form-container-2">
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="form-group">
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="First Name"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder="Email"
                  />
                </div>
                <div className="form-group">
                  <input
                    id="whatsappNo"
                    name="whatsappNo"
                    value={formData.whatsappNo}
                    onChange={handleChange}
                    required
                    placeholder="WhatsApp No"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className={`submit-btn ${isSubmitting ? "submitting" : ""}`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending..." : "Submit"}&nbsp;&nbsp;
                  <FaRegPaperPlane style={{ color: "#D57272" }} />
                </button>

                {submitStatus === "success" && (
                  <div style={{ color: "#ffffff" }}>
                    Message sent successfully!
                  </div>
                )}
                {submitStatus === "error" && (
                  <div style={{ color: "red" }}>
                    Failed to send message. Please try again.
                  </div>
                )}
              </form>

              <div className="social-links">
                <a
                  href="https://www.facebook.com/FONIXSoftwareSolutions"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://www.linkedin.com/company/fonixss"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                >
                  <FaLinkedinIn />
                </a>
                <a
                  href="https://www.youtube.com/@Tutohublk"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="YouTube"
                >
                  <FaYoutube />
                </a>
                <a
                  href="https://wa.link/02ljm9"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="WhatsApp"
                >
                  <FaWhatsapp />
                </a>
                <a href="#" aria-label="Instagram">
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>
          <div className="contact-footer">
            © 2024 FONIX Software Solutions Pvt Lts. All rights reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
