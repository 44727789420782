import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PiMinusBold } from "react-icons/pi";
import { FaArrowLeft } from "react-icons/fa";
import servicesData from "../../data/service-data.json";
import "./projects-page.css";

const ProjectsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const serviceId = location.state?.serviceFilter || "1";
  const service = servicesData.services.find((s) => s.id === serviceId);
  const projects = service?.projects || [];

  const [currentProject, setCurrentProject] = useState(null);

  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const WHATSAPP_NUMBER = "+94770508710";

  useEffect(() => {
    if (projects.length > 0) {
      setCurrentProject(projects[0]);
    }
  }, [serviceId, projects]);

  if (!service) {
    return (
      <div className="projects-page-container">
        <div className="empty-state">
          <h2>Service Not Found</h2>
          <p>The requested service could not be found.</p>
          <button className="back-button" onClick={() => navigate("/")}>
            <FaArrowLeft /> &nbsp; Go Back
          </button>
        </div>
      </div>
    );
  }

  if (projects.length === 0) {
    return (
      <div className="projects-page-container">
        <div className="projects-page-header">
          <div className="page-projects-title">
            <h1>PROJECTS</h1>
          </div>
        </div>
        <div className="empty-state">
          <h2>No Projects Available</h2>
          <p>There are currently no projects available for this service.</p>
          <button className="back-button" onClick={() => navigate("/")}>
            <FaArrowLeft /> &nbsp; Go Back
          </button>
        </div>
      </div>
    );
  }

  if (!currentProject) {
    return (
      <div className="projects-page-container">
        <div className="loading-state">
          <h2>Loading...</h2>
        </div>
      </div>
    );
  }

  const handleProjectChange = (project) => {
    setCurrentProject(project);
  };

  const renderProjectContent = () => {
    return (
      <div className="projects-section">
        <div className="main-projects-content">
          <div className="project-container">
            <h1>{currentProject.title}</h1>
            <p>{currentProject.description}</p>
            <div className="form-row-projects">
              <div className="form-group-projects">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="tel"
                  id="whatsapp"
                  name="whatsapp"
                  placeholder="Enter your Whatsapp"
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </div>

              <button
                type="button"
                className="request-service-btn"
                onClick={handleWhatsAppRedirect}
              >
                Request Service
              </button>
            </div>
          </div>
          <div className="project-container">
            <img
              src={`/images/${currentProject.img}`}
              alt={currentProject.title}
              className="project-image"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderProjectNavigation = () => (
    <div className="projects-footer">
      <div className="sidebar-projects">
        {projects.map((project) => (
          <React.Fragment key={project["p-id"]}>
            <PiMinusBold />
            &nbsp;
            <a
              className={
                currentProject["p-id"] === project["p-id"] ? "active-link" : ""
              }
              onClick={() => handleProjectChange(project)}
              style={{ cursor: "pointer" }}
            >
              PROJECT {project["p-id"]}
            </a>
            &nbsp;
          </React.Fragment>
        ))}
        <PiMinusBold />
      </div>
    </div>
  );

  const handleWhatsAppRedirect = () => {
    if (!email && !whatsapp) {
      alert("Please provide either your email or WhatsApp number.");
      return;
    }

    let message =
      "Hello,I would like to request a service from you. Please find my contact details below.\n";

    if (email) {
      message += `\nEmail: ${email}`;
    }

    if (whatsapp) {
      message += `\nWhatsApp: ${whatsapp}`;
    }

    message += "\n\nThank you.";

    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${WHATSAPP_NUMBER}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="projects-page-container">
      <div className="projects-page-header">
        <div className="page-projects-title">
          <h1>PROJECTS</h1>
        </div>
      </div>
      <div className="projects-content">
        {renderProjectContent()}
        {renderProjectNavigation()}
      </div>
    </div>
  );
};

export default ProjectsPage;
