import React, { useState } from "react";
import servicesData from "../../data/service-data.json";
import "./mobile-services-page.css";

const MobileServicesPage = () => {
  const { services } = servicesData;
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const isButtonDisabled = !email && !whatsapp;

  const WHATSAPP_NUMBER = "+94770508710";

  const handleWhatsAppRedirect = () => {
    let message =
      "Hello,I would like to request a service from you. Please find my contact details below.\n";

    if (email) {
      message += `\nEmail: ${email}`;
    }

    if (whatsapp) {
      message += `\nWhatsApp: ${whatsapp}`;
    }

    message += "\n\nThank you.";

    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${WHATSAPP_NUMBER}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="mobile-services-page">
      <h1 className="mobile-services-title">SERVICES</h1>
      <p className="mobile-services-subtitle">
      At Fonix Software solutions, we offer a wide range of custom software solutions designed to transform your business. Our team of expert developers, designers, and IT professionals leverage cutting-edge technologies to deliver scalable, secure, and user-friendly solutions. We specialize in building software that meets your unique needs, whether you're a startup, mid-sized business, or large enterprise.
      </p>
      <div className="mobile-services-grid">
        {services.map((service, index) => (
          <div key={index} className="mobile-service-card">
            <div className="mobile-service-title">{service.title}</div>
            <div className="mobile-service-icon-container">
              <img
                src={`/images/services/${service.img}`}
                alt={service.title}
                className="mobile-service-icon"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="form-row-mob-services">
        <div className="form-group-mob-services">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="tel"
            id="whatsapp"
            name="whatsapp"
            placeholder="Enter your Whatsapp"
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
          />
        </div>

        <button
          type="button"
          className="mob-services-request-service-btn"
          onClick={handleWhatsAppRedirect}
          disabled={isButtonDisabled}
        >
          Request Service
        </button>
      </div>
    </div>
  );
};

export default MobileServicesPage;
