import React from "react";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { PiMinusBold } from "react-icons/pi";
import "./services-page.css";
import servicesData from "../../data/service-data.json";

const Services = ({ service, onServiceChange }) => {
  const { services } = servicesData;
  const currentService = services.find((s) => s.id === service) || services[0];
  const navigate = useNavigate();

  const handleLearnMore = () => {
    navigate("/projects", {
      state: {
        serviceFilter: currentService.id,
        serviceName: currentService.title,
      },
    });
  };

  const renderServiceContent = () => {
    return (
      <div className={`services-section-${service}`}>
        <div className={`main-services-content-${service}`}>
          <div className={`services-${service}`}>
            <h1>{currentService.title}</h1>
            <p>{currentService.description}</p>
            {currentService.projects && currentService.projects.length > 0 && (
              <button
                type="button"
                className="learn-more-btn"
                onClick={handleLearnMore}
              >
                Learn more &nbsp;&nbsp;
                <FaLongArrowAltRight />
              </button>
            )}
          </div>
          <div className={`services-${service}`}>
            <img
              src={`/images/services/${currentService.img}`}
              alt={currentService.title}
              className="custom-web-1"
            />
          </div>
        </div>
        {renderNavigation()}
      </div>
    );
  };

  const renderNavigation = () => (
    <div className="services-footer">
      <div className="sidebar-services">
        <PiMinusBold />
        &nbsp;
        <a
          className={service === "1" ? "active-link" : ""}
          onClick={() => onServiceChange("1")}
          style={{ cursor: "pointer" }}
        >
          WEB APP DEVELOPMENT
        </a>
        &nbsp;
        <PiMinusBold />
        &nbsp;
        <a
          className={service === "2" ? "active-link" : ""}
          onClick={() => onServiceChange("2")}
          style={{ cursor: "pointer" }}
        >
          MOBILE APP DEVELOPMENT
        </a>
        &nbsp;
        <PiMinusBold />
        &nbsp;
        <a
          className={service === "3" ? "active-link" : ""}
          onClick={() => onServiceChange("3")}
          style={{ cursor: "pointer" }}
        >
          GAME DEVELOPMENT
        </a>
        &nbsp;
        <PiMinusBold />
        &nbsp;
        <a
          className={service === "4" ? "active-link" : ""}
          onClick={() => onServiceChange("4")}
          style={{ cursor: "pointer" }}
        >
          AI SOLUTIONS
        </a>
        &nbsp;
        <PiMinusBold />
        &nbsp;
        <a
          className={service === "5" ? "active-link" : ""}
          onClick={() => onServiceChange("5")}
          style={{ cursor: "pointer" }}
        >
          UI/UX DESIGNING
        </a>
        &nbsp;
        <PiMinusBold />
        &nbsp;
        <a
          className={service === "6" ? "active-link" : ""}
          onClick={() => onServiceChange("6")}
          style={{ cursor: "pointer" }}
        >
          DIGITAL MARKETING
        </a>
        &nbsp;
        <PiMinusBold />
      </div>
    </div>
  );

  return (
    <>
      <div className="services-page-container">
        <div className="services-page-header">
          <div className="page-services-title">
            <h1>SERVICES</h1>
          </div>
        </div>
        {renderServiceContent()}
      </div>
    </>
  );
};

export default Services;
