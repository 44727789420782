import React, { useEffect, useState } from "react";
import "./landing-page.css";

const LandingPage = () => {
  const [mounted, setMounted] = useState(false);
  const text = "WHO WE ARE !";

  const partners = [
    "slasscom.png",
    "royaa.png",
    "ideamart.png",
    "dialog.png",
    "coursehub.png",
    "examhub.png",
  ];

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="landing-page-container">
      <div className="landing-page-header">
        <div className="landing-company-title">
          <h1>
            FONIX
            <span>SOFTWARE SOLUTIONS</span>
          </h1>
        </div>
      </div>
      <div className="landing-section">
        <h1 className="animated-title" aria-label={text}>
          {mounted &&
            text.split("").map((char, idx) => (
              <span
                key={idx}
                style={{
                  animationDelay: `${idx * 0.1}s`,
                }}
              >
                {char === " " ? "\u00A0" : char}
              </span>
            ))}
        </h1>
        <p className="landing-title">
          7+ Years of Excellence as an Award-Winning Digital Solutions Company
        </p>
        <p className="landing-description">
          With over 7 years of experience, FONIX Software Solutions is an
          award-winning company specializing in cutting-edge web development,
          mobile app development, and digital marketing. Our commitment to
          innovation and quality has not only earned us industry-leading
          accolades but also the trust of clients around the globe. From
          creating impactful digital products to driving results through
          strategic marketing, we bring award-winning expertise to every
          project. Let's build something extraordinary together.
        </p>
        <div className="partners-section">
          <div className="partners-container">
            {partners.map((partner, index) => (
              <div key={index} className="partner-item">
                <img
                  src={`/images/partners/${partner}`}
                  alt={`Partner ${index + 1}`}
                  className="partner-image"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
