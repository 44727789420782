import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { PiLineVerticalBold } from "react-icons/pi";
import "./products-page.css";

const Products = ({ section, onSectionChange }) => {
  return (
    <>
      <div
        className={`products-page-container ${
          section === "examhub" ? "examhub-background" : ""
        }`}
      >
        <div className="products-page-header">
          <div className="page-products-title">
            <h1>PRODUCTS</h1>
          </div>
        </div>
        {section === "fedes-ai" ? (
          <div className="products-section">
            <img
              src="/images/fedes-ai.png"
              alt="FedesAI"
              className="fedes-logo"
            />
            <div className="products-sub-content">
              <p>
                FEDES AI is an innovative education solution that uses real-time
                emotion detection technology to enhance student engagement and
                learning outcomes during both online and in-person classes.
              </p>
              <a
                href="https://fedesai.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button type="button" className="fedes-learn-more-btn">
                  Learn More &nbsp;&nbsp;
                  <FaLongArrowAltRight />
                </button>
              </a>
            </div>
          </div>
        ) : (
          <div className="products-section-duplicate">
            <img
              src="/images/examhub.png"
              alt="ExamHub"
              className="examhub-logo"
            />
            <div className="products-sub-content-eh">
              <p>
                ExamHUB is an award-winning education app offering gamified
                quizzes, personalized learning, and multi-syllabus support for
                Global students.
              </p>
              <img
                src="/images/examhub-middle-content.png"
                alt="ExamHub-Mob"
                className="examhub-mob"
              />
              <a
                href="https://examhub.lk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button type="button" className="learn-more-btn">
                  Learn More &nbsp;&nbsp;
                  <FaLongArrowAltRight />
                </button>
              </a>
            </div>
          </div>
        )}
        <div className="sidebar">
          <PiLineVerticalBold />
          <PiLineVerticalBold />
          &nbsp;
          <a
            className={section === "fedes-ai" ? "active-link" : ""}
            onClick={() => onSectionChange("fedes-ai")}
            style={{ cursor: "pointer" }}
          >
            Fedes AI
          </a>
          &nbsp;
          <PiLineVerticalBold />
          &nbsp;
          <a
            className={section === "examhub" ? "active-link" : ""}
            onClick={() => onSectionChange("examhub")}
            style={{ cursor: "pointer" }}
          >
            ExamHub
          </a>
          &nbsp;
          <PiLineVerticalBold />
          <PiLineVerticalBold />
        </div>
      </div>
    </>
  );
};

export default Products;
