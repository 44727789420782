import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header/HeaderPage";
import Sidebar from "./components/Sidebar/SidebarPage";
import LandingPage from "./components/Landing/LandingPage";
import HeroPage from "./components/Hero/HeroPage";
import ProductsPage from "./components/Products/ProductsPage";
import ServicesPage from "./components/Services/ServicesPage";
import MobileServicesPage from "./components/MobileServices/MobileServicesPage";
import MobileProductsPage from "./components/MobileProducts/MobileProductsPage";
import ProjectsPage from "./components/Projects/ProjectsPage";
import ContactPage from "./components/Contact/ContactPage";
import "./App.css";

function App() {
  const [activeFedesAISection, setActiveFedesAISection] = useState("fedes-ai");
  const [activeExamHubSection, setActiveExamHubSection] = useState("examhub");
  const [activeServices, setActiveServices] = useState({
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleFedesAISectionChange = (newSection) => {
    setActiveFedesAISection(newSection);
  };

  const handleExamHubSectionChange = (newSection) => {
    setActiveExamHubSection(newSection);
  };

  const handleServiceSectionChange = (id, newSection) => {
    setActiveServices((prev) => ({ ...prev, [id]: newSection }));
  };

  function AppContent() {
    const location = useLocation();

    return (
      <div className="App">
        <Header />
        {location.pathname !== "/projects" && <Sidebar />}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div id="landing">
                  <LandingPage />
                </div>
                <div id="hero">
                  <HeroPage />
                </div>
                {windowWidth > 1024 && (
                  <div id="products">
                    <ProductsPage
                      section={activeExamHubSection}
                      onSectionChange={handleExamHubSectionChange}
                    />
                    <ProductsPage
                      section={activeFedesAISection}
                      onSectionChange={handleFedesAISectionChange}
                    />
                  </div>
                )}
                {windowWidth <= 1024 && (
                  <div id="products">
                    <MobileProductsPage />
                  </div>
                )}
                {windowWidth > 1024 && (
                  <div id="services">
                    {[1, 2, 3, 4, 5, 6].map((id) => (
                      <ServicesPage
                        key={id}
                        service={activeServices[id]}
                        onServiceChange={(newSection) =>
                          handleServiceSectionChange(id.toString(), newSection)
                        }
                      />
                    ))}
                  </div>
                )}
                {windowWidth <= 1024 && (
                  <div id="services">
                    <MobileServicesPage />
                  </div>
                )}
                <div id="contact">
                  <ContactPage />
                </div>
              </>
            }
          />
          <Route path="/projects" element={<ProjectsPage />} />
        </Routes>
      </div>
    );
  }

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
