import React, { useState, useEffect } from "react";
import carouselData from "../../data/carousel-data.json";
import "./hero-page.css";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isNext, setIsNext] = useState(true);

  const TRANSITION_INTERVAL = 5000;
  const items = carouselData.items;

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
      setIsNext(true);
    }, TRANSITION_INTERVAL);

    return () => clearInterval(timer);
  }, [items.length]);

  const getItemForPosition = (position) => {
    let index;
    switch (position) {
      case 1:
        index = (currentIndex - 1 + items.length) % items.length;
        break;
      case 2:
        index = currentIndex;
        break;
      case 3:
        index = (currentIndex + 1) % items.length;
        break;
      case 4:
        index = (currentIndex + 2) % items.length;
        break;
      case 5:
        index = (currentIndex + 3) % items.length;
        break;
      default:
        index = currentIndex;
    }
    return items[index];
  };

  const CarouselItem = ({ item, index }) => (
    <div className={`item item-${index}`} style={{ color: "#9C9C9C" }}>
      <img src={`/images/awards/${item.img}`} alt={item.topic} />
      <div className="introduce">
        <div className="title">Award Winning</div>
        <div className="topic">{item.topic}</div>
        <div className="des">{item.des}</div>
        {item.awards && (
          <ul className="awards-list">
            {item.awards.map((award, idx) => (
              <li key={idx}>{award}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="hero-page-container">
        <div className="page-header">
          <div className="company-title">
            <h1>AWARDS</h1>
          </div>
        </div>
        <div className="hero-section">
          <div className={`carousel ${isNext ? "next" : "prev"}`}>
            <div className="list">
              {[1, 2, 3, 4, 5].map((position) => (
                <CarouselItem
                  key={position}
                  item={getItemForPosition(position)}
                  index={position}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Carousel;
