import React, { useState, useEffect } from "react";
import "./sidebar-page.css";

const Sidebar = () => {
  const [activeSection, setActiveSection] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = [
    { id: "landing", mark: "00", label: "Home" },
    { id: "hero", mark: "01", label: "Awards" },
    { id: "products", mark: "02", label: "Products" },
    { id: "services", mark: "03", label: "Services" },
    { id: "contact", mark: "04", label: "Contact" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const sections = navItems.map((item, index) => {
        const element = document.getElementById(item.id);
        if (element) {
          const rect = element.getBoundingClientRect();
          return {
            index,
            distance: Math.abs(rect.top),
          };
        }
        return { index, distance: Infinity };
      });

      const nearest = sections.reduce((acc, curr) =>
        curr.distance < acc.distance ? curr : acc
      );

      setActiveSection(nearest.index);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className={`ruler-sidebar ${isMenuOpen ? "open" : ""}`}>
        <div className="ruler-container">
          <div className="ruler-line"></div>
          <div className="ruler-marks">
            {navItems.map((item, index) => (
              <React.Fragment key={item.id}>
                <div
                  className={`ruler-mark ${
                    activeSection === index ? "active" : ""
                  }`}
                  style={{ top: `${(index * 100) / (navItems.length - 1)}%` }}
                  onClick={() => handleNavClick(item.id)}
                >
                  <span className="ruler-number">{item.mark}</span>
                  <div className="ruler-tick"></div>
                </div>
                {index < navItems.length - 1 && (
                  <div
                    className="ruler-indicator-dot"
                    style={{
                      top: `${((index + 0.5) * 100) / (navItems.length - 1)}%`,
                    }}
                  ></div>
                )}
              </React.Fragment>
            ))}
            <div
              className="ruler-indicator"
              style={{
                top: `${(activeSection * 100) / (navItems.length - 1)}%`,
              }}
            >
              <div className="ruler-indicator-line"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`hamburger-menu ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="hamburger-line"></div>
        <div className="hamburger-line"></div>
        <div className="hamburger-line"></div>
      </div>
      <nav className={`mobile-nav ${isMenuOpen ? "open" : ""}`}>
        {navItems.map((item) => (
          <a
            key={item.id}
            className="mobile-nav-item"
            href={`#${item.id}`}
            onClick={() => handleNavClick(item.id)}
          >
            {item.label}
          </a>
        ))}
      </nav>
    </>
  );
};

export default Sidebar;
